import React, { useRef, useEffect } from "react";
import gsap from "gsap";
import { personalDetails } from "../Details";

function Home() {
  const { name, tagline, img } = personalDetails;
  const h11 = useRef();
  const h12 = useRef();
  const h13 = useRef();
  const myimageref = useRef();
  useEffect(() => {
    const tl = gsap.timeline();
    tl.from(
        h12.current,
        {
          x: "-100%",
          delay: 0.8,
          opacity: 0,
          duration: 2,
          ease: "Power3.easeOut",
        },
        "<"
      )
      .from(
        h13.current,
        {
          x: "-100%",
          delay: 0.5,
          opacity: 0,
          duration: 2,
          ease: "Power3.easeOut",
        },
        "<"
      )
      .from(
        myimageref.current,
        {
          x: "200%",
          delay: 0.5,
          opacity: 0,
          duration: 2,
          ease: "Power3.easeOut",
        },
        "<"
      );
  }, []);

    return (
        <main className="container mx-auto max-width section md:flex justify-between items-center">
            <div>
                <h1
                    ref={h12}
                    className="text-2xl bg-clip-text bg-gradient text-transparent md:text-4xl xl:text-5xl xl:leading-tight font-bold mb-48"
                >
                    {name}
                </h1>
                <h2
                    ref={h13}
                    className="text-xl text-dark-heading dark:text-light-heading md:text-2xl xl:text-2xl xl:leading-tight font-bold"
                >
                    {tagline}
                </h2>
            </div>
            <div className="mt-5 md:mt-0">
                <img ref={myimageref} className="w-1/2 md:ml-auto" src={img} alt="Pavan MG" />
            </div>
        </main>
    );

}

export default Home;
