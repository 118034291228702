import React from "react";

function Footer() {
    return (
        <footer className="flex justify-center items-center mx-auto py-1 fixed bottom-0 bg-white dark:bg-dark-mode w-full">
            <p className="text-xs text-center text-dark-content dark:text-light-content">
                <a
                    className="font-medium"
                    target="_blank"
                    href="https://raress.tech"
                    rel="noreferrer noopener"
                >
                    Rare Secure Systems - © 2024 Rareș-Andrei Sărmășag
                </a>
            </p>
        </footer>
    );
}
export default Footer;
