// Enter all your detials in this file
// Logo images
import logogradient from "./assets/logo.png";
import logo from "./assets/logo.png";
// Profile Image
import profile from "./assets/profile.jpg";
// Tech stack images
import ibmzpc from "./assets/techstack/ibmzpc.png";
import imsdpt from "./assets/techstack/imsdpt.png";
import imsfpi from "./assets/techstack/imsfpi.png";
import imsds from "./assets/techstack/imsds.png";
import imsdr from "./assets/techstack/imsdr.png";
import imstmpa from "./assets/techstack/imstmpa.png"
import ibmrpa from "./assets/techstack/ibmrpa.png"
import ccna2 from "./assets/techstack/ccna2.png"
import ccna3 from "./assets/techstack/ccna3.png"
import caac from "./assets/techstack/caa-c.png"
import caaco from "./assets/techstack/caa-co.png"
import cf from "./assets/techstack/cf.png"
import qrsf from "./assets/techstack/qrsf.png"
import sztp from "./assets/techstack/sztp.png"
import ctm from "./assets/techstack/ctm.png"
import imshaldb from "./assets/techstack/imshaldb.png"
import imsdbctl from "./assets/techstack/imsdbctl.png"
import imspdtm from "./assets/techstack/imspdtm.png"
import imsd from "./assets/techstack/imsd.png"
import imssq from "./assets/techstack/imssq.png"
import zosss from "./assets/techstack/zosss.png"
import abozos from "./assets/techstack/abozos.png"
import ibmlo from "./assets/techstack/ibmlo.png"
import ibmzci from "./assets/techstack/ibmzci.png"

// Porject Images
import projectImage1 from "./assets/projects/numericalmethods.png";
import projectImage2 from "./assets/projects/datatrain.png";
import projectImage3 from "./assets/projects/ap0dl.png";
import projectImage4 from "./assets/projects/ioctl.png";
import projectImage5 from "./assets/projects/project5.jpg";
import projectImage6 from "./assets/projects/project6.jpg";

// Logos
export const logos = {
  logogradient: logogradient,
  logo: logo,
};

// Enter your Personal Details here
export const personalDetails = {
  name: "Rareș-Andrei Sărmășag",
  tagline: "Systems & Security",
  img: profile,
  about: `I am a dedicated Computer Engineering 2nd year student with a strong passion for Systems, Cybersecurity and Automations. Driven by an innate curiosity and a methodical approach, I delve into system development and automation, constantly exploring innovative ways to enhance efficiency and security. My skill set includes C/C++, Python, Lua and deep systems knowledge which I apply to create streamlined and secure solutions.`,
};

// Enter your Social Media URLs here
export const socialMediaUrl = {
  linkdein: "https://www.linkedin.com/",
  github: "https://www.github.com/rares9301",
  instagram: "https://www.instagram.com/rares9301",
};

// Enter your Work Experience here
export const workDetails = [
  {
    Position: "Operating Systems - Teaching Assistant",
    Company: `UNSTPB`,
    Location: "Bucharest, Romania",
    Type: "TA",
    Duration: "Oct 2024 - Present",
  },
    {
    Position: "Security, Integrity & Anticheat Division",
    Company: `Ubisoft`,
    Location: "Full-Remote",
    Type: "Internship",
    Duration: "Nov 2022 - Nov 2023",
  },
];

// Enter your Education Details here
export const eduDetails = [
  {
    Position: "Faculty of Automatic Control and Computer Science",
    Company: "UNSTPB",
    Location: "Bucharest, Romania",
    Type: "Bachelor",
    Duration: "Oct 2023 - Present",
  },
  {
    Position: "\"Mircea cel Bătrân\" National College",
    Company: `CNMB`,
    Location: "Constanța, Romania",
    Type: "College",
    Duration: "2019 - 2023 (4 years)",
  },
];

// Tech Stack and Tools
export const techStackDetails = {
  ibmzpc: ibmzpc,
  imsdpt: imsdpt,
  imsfpi: imsfpi,
  imsds: imsds,
  imsdr: imsdr,
  imstmpa: imstmpa,
  ibmrpa: ibmrpa,
  ccna2: ccna2,
  ccna3: ccna3,
  caac: caac,
  caaco: caaco,
  cf: cf,
  qrsf: qrsf,
  sztp: sztp,
  ctm: ctm,
  imshaldb: imshaldb,
  imsdbctl: imsdbctl,
  imspdtm: imspdtm,
  imsd: imsd,
  imssq: imssq,
  zosss: zosss,
  abozos: abozos,
  ibmlo: ibmlo,
  ibmzci: ibmzci,
};

// Enter your Project Details here
export const projectDetails = [
  {
    title: "anomaly-detection",
    image: projectImage1,
    description: `Simple but efficient anomaly detection, kernel regression and markov chain algorithms. Includes an 
    introduction into generative solutions such as LLMs`,
    techstack: "MATLAB",
    previewLink: "https://raress.tech/anomaly-detection",
    githubLink: "https://github.com/rares9301/anomaly-detection",
  },
  {
    title: "datatrain",
    image: projectImage2,
    description: `Modular tool for anomaly detection, employing methods like Z-score and threshold techniques to efficiently
    identify outliers in datasets across various domains.`,
    techstack: "Python",
    previewLink: "https://en.wikipedia.org/wiki/Anomaly_detection",
    githubLink: "https://github.com/rares9301/datatrain",
  },
  {
    title: "ap0dl",
    image: projectImage3,
    description: `Comprehensive automation tool that streamlines the process of searching, streaming, and downloading anime,
    without the overhead of Selenium or JavaScript evaluators.`,
    techstack: "Python",
    previewLink: "https://medium.com/geekculture/scraping-a-dynamic-website-without-selenium-part-i-c1fec0d302bd",
    githubLink: "https://github.com/rares9301/ap0dl",
  },
  {
    title: "ioctl-driver",
    image: projectImage4,
    description: `Sophisticated kernel-level tool that employs direct (read/write) memory access techniques to seamlessly 
    interact with user-mode applications using DeviceIoControl.`,
    techstack: "C++/sys",
    previewLink: "https://learn.microsoft.com/en-us/windows/win32/api/ioapiset/nf-ioapiset-deviceiocontrol",
    githubLink: "https://github.com/rares9301/ioctl-driver",
  },
];

// Enter your Contact Details here
export const contactDetails = {
  email: "inquiries@raress.tech",
};
