import React from "react";
import { techStackDetails } from "../Details";
import CredlyBadge from "../CredlyBadge";
import imshaldb from "../assets/techstack/imshaldb.png";
import imsdbctl from "../assets/techstack/imsdbctl.png";
import imspdtm from "../assets/techstack/imspdtm.png";
import imsd from "../assets/techstack/imsd.png";
import imssq from "../assets/techstack/imssq.png";
import zosss from "../assets/techstack/zosss.png";
import abozos from "../assets/techstack/abozos.png";
import ibmlo from "../assets/techstack/ibmlo.png";
import ibmzci from "../assets/techstack/ibmzci.png";

function Technologies() {
  const {
    ibmzpc,
      imsdpt,
      imsfpi,
      imsds,
      imsdr,
      imstmpa,
      ibmrpa,
      ccna2,
      ccna3,
      caac,
      caaco,
      cf,
      qrsf,
      sztp,
      ctm,
      imshaldb,
      imsdbctl,
      imspdtm,
      imsd,
      imssq,
      zosss,
      abozos,
      ibmlo,
      ibmzci,
  } = techStackDetails;
  return (
      <main className="container mx-auto max-width pt-10 pb-20 ">
          <section>

              <h1 className="text-2xl text-dark-heading dark:text-light-heading md:text-4xl xl:text-5xl xl:leading-tight font-bold">
                  Credits
              </h1>
              <p className="text-content py-2 lg:max-w-3xl mb-4">
                  Credits are categorized by their main theme, even if they may encompass multiple topics.
              </p>
          </section>
          <section>
              <h1 className="text-xl text-dark-heading dark:text-light-heading md:text-3xl xl:text-4xl xl:leading-tight font-bold">
                  Systems
              </h1>
          </section>
          <section className="grid grid-cols-4 md:grid-cols-5 lg:grid-cols-6 items-center gap-10 pt-6">
              <img src={ibmzpc} title="IBM Systems Professional" alt=""/>
              <a href="https://www.credly.com/badges/a49a3a5e-e3a0-4870-baed-95a0af0f060d/public_url" target="_blank" title="Certificate Title">
                  <img src={imsdpt} title="IMSd P&T" alt=""/>
              </a>
              <a href="https://www.credly.com/badges/ca211cec-3c79-4eb2-883e-32fd4ee15410/public_url" target="_blank" title="Certificate Title">
                  <img src={imsfpi} title="IMSd FPI" alt=""/>
              </a>
              <a href="https://www.credly.com/badges/cb4f4b3d-fed0-42eb-a5c8-f07f9c208ca6/public_url" target="_blank" title="Certificate Title">
                  <img src={imsds} title="IMSd DS" alt=""/>
              </a>
              <a href="https://www.credly.com/badges/c4e78e27-d53f-4959-a583-59e1b08cda6d/public_url" target="_blank" title="Certificate Title">
                  <img src={imsdr} title="IMSd Repair" alt=""/>
              </a>
              <a href="https://www.credly.com/badges/0f174faf-1c5d-4bed-a87a-7ad08bf4eae1/public_url" target="_blank" title="Certificate Title">
                  <img src={imstmpa} title="IMSd TMPA" alt=""/>
              </a>
              <a href="https://www.credly.com/badges/008572d0-127c-4f38-be4b-ec8479b40b8a/public_url" target="_blank" title="Certificate Title">
                  <img src={imshaldb} title="IMSd HALDB" alt=""/>
              </a>
              <a href="https://www.credly.com/badges/ad0d69a8-8362-4c42-bcf3-776d685f1512/public_url" target="_blank" title="Certificate Title">
                  <img src={imsdbctl} title="IMSd DBCTL" alt=""/>
              </a>
              <a href="https://www.credly.com/badges/b423f0a9-c866-4a14-80d4-942f15d2b7a7/public_url" target="_blank" title="Certificate Title">
                  <img src={imspdtm} title="IMSd DTM" alt=""/>
              </a>
              <a href="https://www.credly.com/badges/2af97da6-c6ea-4afe-a544-a98af37e9c28/public_url" target="_blank" title="Certificate Title">
                  <img src={imsd} title="IMSd DIAG" alt=""/>
              </a>
              <a href="https://www.credly.com/badges/51003d21-b255-4b7b-a758-bf15d548c239/public_url" target="_blank" title="Certificate Title">
                  <img src={imssq} title="IMSd SQ" alt=""/>
              </a>
              <a href="https://www.credly.com/badges/7ed9b367-8ad3-4d48-8004-dad8788d08fb/public_url" target="_blank" title="Certificate Title">
                  <img src={zosss} title="z/OS SSS" alt=""/>
              </a>
              <a href="https://www.credly.com/badges/b0225e02-be40-49f5-a798-bb3a69d52e3f/public_url" target="_blank" title="Certificate Title">
                  <img src={abozos} title="z/OS ABO" alt=""/>
              </a>
              <a href="https://www.credly.com/badges/8d07431e-8278-47ff-92d7-164ecb6ed493/public_url" target="_blank" title="Certificate Title">
                  <img src={ibmlo} title="IBM LinuxONE" alt=""/>
              </a>
              <a href="https://www.credly.com/badges/f7000a91-47de-4511-8328-3d2f7b1bcdee/public_url" target="_blank" title="Certificate Title">
                  <img src={ibmzci} title="z/OS C-sec" alt=""/>
              </a>
          </section>
          <section>
              <h1 className="text-xl pt-10 text-dark-heading dark:text-light-heading md:text-3xl xl:text-4xl xl:leading-tight font-bold">
                  Security
              </h1>
          </section>
          <section className="grid grid-cols-4 md:grid-cols-5 lg:grid-cols-6 items-center gap-10 pt-6">
              <a href="https://www.credly.com/badges/dfe30b11-993e-433d-8fea-e1b9fb7c3111/public_url" target="_blank" title="Certificate Title">
                  <img src={cf} title="SKB CS-f" alt=""/>
              </a>
              <a href="https://www.credly.com/badges/8f7334a4-aba3-4bf3-a550-12e3b3fa3ba2/public_url" target="_blank" title="Certificate Title">
                  <img src={qrsf} title="IBM QRSf" alt=""/>
              </a>
              <a href="https://www.credly.com/badges/4a246dd6-13e2-465e-a27c-edd3b25de330/public_url" target="_blank" title="Certificate Title">
                  <img src={sztp} title="IBM SZTp" alt=""/>
              </a>
              <a href="https://www.credly.com/badges/eb971ead-1ed5-4867-a7e5-94683d62d651/public_url" target="_blank" title="Certificate Title">
                  <img src={ctm} title="IBM C-TM" alt=""/>
              </a>
          </section>
          <section>
              <h1 className="text-xl pt-10 text-dark-heading dark:text-light-heading md:text-3xl xl:text-4xl xl:leading-tight font-bold">
                  Cloud & Automation
              </h1>
          </section>
          <section className="grid grid-cols-4 md:grid-cols-5 lg:grid-cols-6 items-center gap-10 pt-6">
              <a href="https://www.credly.com/badges/e9cd2170-19a6-471b-8918-6923cd93bd96/public_url" target="_blank" title="Certificate Title">
                  <img src={ibmrpa} title="IBM RPA" alt=""/>
              </a>
              <a href="https://www.credly.com/badges/7352f25e-2fe5-40ef-a3c1-0c13ecc28a9d/public_url" target="_blank" title="Certificate Title">
                  <img src={caac} title="IBM CAA-C P&T" alt=""/>
              </a>
              <a href="https://www.credly.com/badges/5b4dc377-fd51-4a87-a6fd-c5948cb00278/public_url" target="_blank" title="Certificate Title">
                  <img src={caaco} title="IBM CAA-CO" alt=""/>
              </a>
          </section>
          <section>
              <h1 className="text-xl pt-10 text-dark-heading dark:text-light-heading md:text-3xl xl:text-4xl xl:leading-tight font-bold">
                  Networks
              </h1>
          </section>
          <section className="grid grid-cols-4 md:grid-cols-5 lg:grid-cols-6 items-center gap-10 pt-6">
              <a href="https://www.credly.com/badges/a542855b-c749-4872-961c-2c90ee96aeec/public_url" target="_blank" title="Certificate Title">
                  <img src={ccna3} title="CCNA3" alt=""/>
              </a>
              <a href="https://www.credly.com/badges/a723a702-1904-4271-a825-023bba574ab5/public_url" target="_blank" title="Certificate Title">
                  <img src={ccna2} title="CCNA2" alt=""/>
              </a>
          </section>
      </main>
  );
}

export default Technologies;
